




import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated'
    })
  }
})
export default class LogoutPage extends Vue {
  mounted() {
    this.$store.dispatch('auth/logout').then(() => {
      this.$router.push('/login')
    })
  }
}
